import $ from "jquery";

globalThis.jQuery = $;

// GENERAL STYLING
import './styles.scss';

// FONT AWESOME
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {faUser, faBars, faChevronDown, faChevronLeft, faChevronRight} from '@fortawesome/pro-solid-svg-icons'

library.add(faUser, faBars, faChevronDown, faChevronLeft, faChevronRight);
dom.watch();

// SLICK SLIDER
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';


// AOS
// import AOS from 'aos';
// import 'aos/dist/aos.css';


(function ($) {

    $(function () {

        'use strict';

        // DOM ready, take it away

        // MOBILE MENU submenu
        $('<div class="subarrow"><i class="fa-solid fa-chevron-down"></i></div>').insertAfter('.mobile-menu li.menu-item-has-children > a');
        $('.mobile-menu .subarrow').on('click', function () {
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
            } else {
                $(this).parent().addClass('active');
            }
        });


        // MOBILE TOGGLE
        // ===============================================

        $('.menu-toggle').on('click', function () {
            if ($('.mobile-menu').hasClass('active')) {
                $('.mobile-menu, .page-overlay').removeClass('active');
                $('body').removeClass('overflow-hidden');
            } else {
                $('.mobile-menu, .page-overlay').addClass('active');
                $('body').addClass('overflow-hidden');
            }
        });
        $('.page-overlay').on('click', function () {
            $('.mobile-menu, .page-overlay').removeClass('active');
            $('body').removeClass('overflow-hidden');
        });

        $('.mobile-menu ul li a, .header .logo').on('click', function () {
            $('body').removeClass('overflow-hidden');
        });

        // Close mobile menu on click
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.mobile-menu').length && !$target.closest('.menu-toggle').length && $('.mobile-menu').hasClass('active')) {
                $('.mobile-menu').removeClass('active');
                $('body').removeClass('overflow-hidden');
            }

        });


        // Scrollbar calc for tailwind breakout
        // ===============================================
        $('html').css('--twcb-scrollbar-width', (window.innerWidth - $('html').width()) + "px");

        // HEADER SCROLLED
        // ===============================================
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 150) {
                $("header, body").addClass("scrolled");
            }

            if (scroll < 50) {
                $("header, body").removeClass("scrolled");
            }
        });

        // AOS.init({
        //   duration: 400,
        //   offset: 0, // offset (in px) from the original trigger point
        //   once: true, // whether animation should happen only once - while scrolling down
        //   anchorPlacement: 'top-bottom', // define where the AOS animations will be triggered
        // });
        // window.addEventListener('load', AOS.refresh);

        $('.home-slider').slick({
            dots: true,
            infinite: true,
            speed: 1000,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            accessibility: true,
            // prevArrow: $('.header-prev'),
            // nextArrow: $('.header-next'),
        });

        $('.service-slider').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 2000,
            accessibility: true,
            prevArrow: $('.service-prev'),
            nextArrow: $('.service-next'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        centerPadding: '0',
                    }
                }
            ]
        });

        $('.review-slider').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 2000,
            accessibility: true,
            prevArrow: $('.review-prev'),
            nextArrow: $('.review-next'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

})(jQuery);